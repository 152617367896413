import React from "react";
import config from "./config";
import accessToken from "./token";

const online = {
  is: false,

  tick: () => {
    online.fetch();
  },
  goOffline: () => {
    if (online.is) {
      online.is = false;
      online.dispatch("onlineGoOffline");
      online.log("Went offline");
    }
  },
  goOnline: () => {
    if (!online.is) {
      online.is = true;
      online.dispatch("onlineGoOnline");
      online.log("Back On Line");
    }
  },
  dispatch: (type, detail = {}) => {
    window.dispatchEvent(new CustomEvent(type, { detail }));
  },
  fetch: async () => {
    let token = accessToken.get();
    if (token) {
      let dt = new Date();
      dt.setMilliseconds(
        dt.getMilliseconds() - (config.apiCheckInterval + 5000)
      );

      const body = JSON.stringify({
        search: [{ field: "datetime", value: dt, compare: "GEQ" }],
        reclimit: 500,
        orderby: ["datetime DESC"],
      });

      let json = null;
      let response = null;
      let headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("Authorization", `Bearer ${token}`);
      const url = `${config.apiDomain}${config.apiPath}/notification/list`;

      try {
        response = await fetch(url, {
          method: "POST",
          headers,
          body,
          mode: "cors",
          cache: "no-store",
        });
        if (response) {
          json = await response.json();
          if (json?.error) {
            return online.error(json?.error?.message);
          }
        }
        online.goOnline();
      } catch (e) {
        online.goOffline();
        return online.error(e.message);
      }
      if (json) {
        let items = {};
        (Array.isArray(json) ? json : [json]).forEach((n) => {
          if (!items[n.object]) {
            items[n.object] = n;
          }
        });

        let itemsCount = Object.keys(items).length;

        if (itemsCount > 0) {
          online.dispatch("onlineChanged", { items });
          online.log("messages count " + itemsCount);
        }
      }
      return false;
    }
  },
  error: (message) => {
    if (config.debug) {
      console.log(`%c OnLine: ERROR "${message}"`, "color: orange");
    }
    return false;
  },
  log: (message) => {
    if (config.debug) {
      console.log(`%c OnLine: ${message}`, "color: cyan");
    }
  },
};
// if (!window.onlineInterval && config.apiCheckInterval) {
//   window.setInterval(() => online.tick(), config.apiCheckInterval);
// }

function withOnline(Component, mapOnlineProps) {
  const propsKeys = Object.keys(mapOnlineProps);

  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        data: {},
        passProps: {},
      };
    }
    componentDidMount() {
      window.addEventListener("onlineChanged", this.onOnlineChanged);
    }

    componentWillUnmount() {
      window.removeEventListener("onlineChanged", this.onOnlineChanged);
    }

    onOnlineChanged = (e) => {
      let data = e?.detail?.items[this.props.id];
      if (data) {
        this.setState(({ passProps }) => {
          propsKeys.forEach((propName) => {
            passProps[propName] = mapOnlineProps[propName](data);
          });
          return { passProps };
        });

        this.setState({ data });
      }
    };

    componentDidUpdate(prevProps) {
      let { passProps } = this.state;
      let anyChanges = false;

      propsKeys.forEach((propName) => {
        if (prevProps[propName] !== this.props[propName]) {
          passProps[propName] = this.props[propName];
          anyChanges = true;
        }
      });
      if (anyChanges) this.setState({ passProps });
    }

    render() {
      const { passProps } = this.state;

      return <Component {...this.props} {...passProps} />;
    }
  };
}

export { online, withOnline };
